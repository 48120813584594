import './App.css';
import Navbar from './components/Navbar.js';
import './animate.min.css'
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Popover } from '@typeform/embed-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { library } from '@fortawesome/fontawesome-svg-core'
import ReactGA from "react-ga4";
import { HelmetProvider } from 'react-helmet-async';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import useScrollToTop from './hooks/useScrollToTop.js'
import { useCalendlyEventListener } from 'react-calendly';
import ReactPixel from 'react-facebook-pixel';
import Success from './landing-pages/Success.js';
import FAQ from './components/FAQ.js';



const PT = lazy(() => import('./components/pages/PrecissionTeaching/PT.jsx'));
const BelgianPackage = lazy(() => import('./components/pages/Curriculum/BelgianPackage'));
const Privacy = lazy(() => import('./components/pages/Privacy'));
const SubjectPage = lazy(() => import('./landing-pages/components/SubjectPage'));
const IbALevels = lazy(() => import('./components/pages/Curriculum/IbALevels.js'));
const BlogPage = lazy(() => import('./blogs/components/BlogPage.js'));
const BlogList = lazy(() => import('./blogs/components/BlogList.js'));
const AdmissionProcess = lazy(() => import('./components/pages/Admission-Process'));
const Curriculum = lazy(() => import('./components/pages/Curriculum/Curriculum'));
const CambridgeExams = lazy(() => import('./components/pages/Curriculum/CambridgeExams'));
const AboutUs = lazy(() => import('./components/pages/Aboutus'));
const HomePage = lazy(() => import('./components/homepage/Homepage'));
const UPCurriculum = lazy(() => import('./components/pages/Up-Curriculum'));
const ISFCurriculum = lazy(() => import('./components/pages/ISF-Curriculum'));
const IGCSECurriculum = lazy(() => import('./components/pages/IGCSE-Curriculum'));
const ACurriculum = lazy(() => import('./components/pages/A-Curriculum'));
const ASubjectPage = lazy(() => import('./components/pages/Curriculum/renderSubjects/ASubjectPage'));
const FutureTech = lazy(() => import('./components/pages/Curriculum/FutureTech'));
const WhyUs = lazy(() => import('./components/pages/Whyus'));
const Pricing = lazy(() => import('./components/pages/Pricing'));
const NewPricing = lazy(() => import('./components/pages/NewPricing'));
const FooterComponent = lazy(() => import('./components/Footer'));
const Mindset = lazy(() => import('./components/pages/Curriculum/Mindset'));
const TermsUse = lazy(() => import('./components/pages/TermsUse'));
const Wellbeing = lazy(() => import('./components/pages/Well-Being/Well-Being'));
const Compare = lazy(() => import('./components/pages/Compare/Compare'));

const LandingPage = lazy(() => import('./landing-pages/LandingPage'));
const LandingPage1 = lazy(() => import('./landing-pages/Google-Ads/LandingPage-1'));
const LandingPage2 = lazy(() => import('./landing-pages/Google-Ads/LandingPage-2'));
const LandingPage3 = lazy(() => import('./landing-pages/Google-Ads/LandingPage-3'));
const LandingPage4 = lazy(() => import('./landing-pages/Google-Ads/LandingPage-4'));
const LandingPage5 = lazy(() => import('./landing-pages/Google-Ads/LandingPage-5'));
const LandingPage6 = lazy(() => import('./landing-pages/Google-Ads/LandingPage-6'));
const LandingPage7 = lazy(() => import('./landing-pages/Google-Ads/LandingPage-7'));
const LandingPage8 = lazy(() => import('./landing-pages/Google-Ads/LandingPage-8')); 
const LandingPage9 = lazy(() => import('./landing-pages/Google-Ads/LandingPage-9')); 
const LandingPage10 = lazy(() => import('./landing-pages/Google-Ads/LandingPage-10')); 

const RemoteLandingPage = lazy(() => import('./landing-pages/RemoteLandingPage.js')); 

const options = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init('2111381409305765', options);
ReactPixel.pageView(); 

ReactGA.initialize("G-4FV2KJCYWG");
library.add(faSpinner);
function ScrollToTop() {
  useScrollToTop();

  return null; // This component doesn't render anything
}


function App() {  

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      
      ReactPixel.track("calls booked", e.data.payload); 
      console.log(e.data.payload)
      window.dataLayer.push({
        'event': 'call',
        'payload': e.data.payload, // You might want to send specific data here
        
      })
      fetch('https://script.google.com/macros/s/AKfycbyBdCf0lXJCuq15dJuEM61IntrFV36MfZueVE4Jok4mawexAvLKKMs-TOZ5ki7iTx7E/exec', {
        method: 'POST',
        body: JSON.stringify({
          payload: e.data.payload,
          gclid: localStorage.getItem("gclid") || ''
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch(error => {
        console.error('Error:', error);
      });

//window.location.href = "/call-booked-success";
    },
  });


  return (
    <HelmetProvider>
      <div className=''>
      
        <Tooltip id="enquiry" />
        <a data-tooltip-id="enquiry" 
          data-tooltip-content="Any questions?"
          className='z-[9999] bottom-0 right-0 fixed m-5 flex items-center justify-center text-6xl overscroll-none wave' 
          href="https://apply.inventumonline.com/question#tally-open=wgdboD&tally-overlay=1&tally-emoji-text=👋&tally-emoji-animation=wave"
          >
          👋
        </a>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Suspense fallback={ <div className='h-screen w-screen bg-dd pt-40 text-7xl text-center mx-auto justify-center text-white flex items-center'><FontAwesomeIcon icon={faSpinner} spin /></div>} >
        <Routes>
                <Route path='/' element={<HomePage />}/>
                <Route path='/call-booked-success' element={<Success />}/>
                {/* About Us */}
                <Route path='/aboutus' element={<AboutUs />}/>
                <Route path='/aboutus-nigeria' element={<AboutUs nigeria={true} />}/>
                {/* Why Us */}
                <Route path='/whyus' element={<WhyUs />} />
                {/* Well Being */}
                <Route path='/well-being' element={<Wellbeing />} />
                {/* Curriculum */}
                <Route path='/curriculum' element={<Curriculum />}></Route>
                <Route path='/ib-vs-alevels' element={<IbALevels />}></Route>
                <Route path='/cambridge-and-pearson' element={<Compare />}></Route>
                <Route path='/upperprimary' element={<UPCurriculum />}></Route>
                <Route path={`/upperprimary/:subjects`} element={<ASubjectPage/>}></Route>
                <Route path='/middleschool' element={<ISFCurriculum />}></Route>
                <Route path={`/middleschool/:subjects`} element={<ASubjectPage/>}></Route>
                <Route path='/igcse' element={<IGCSECurriculum />}></Route>
                <Route path={`/igcse/:subjects`} element={<ASubjectPage/>}></Route>
                <Route path='/a-levels' element={<ACurriculum />}></Route>
                <Route path={`/a-levels/:subjects`} element={<ASubjectPage/>}></Route>
                <Route path={'/future-technologies'} element={<FutureTech />}></Route>
                <Route path={'/personal-mindset-education'} element={<Mindset />}></Route>
                {/* Pricing */}
                <Route path='/tiers-and-fees' element={<Pricing />} />
                <Route path='/journeys-and-fees' element={<NewPricing />} />
                <Route path='/admission-process' element={<AdmissionProcess />} />
                <Route path={'/belgian-package'} element={<BelgianPackage />}></Route>
                <Route path={'/cambridge-exams'} element={<CambridgeExams />}></Route>

                <Route path='/terms-of-use' element={<TermsUse />} />
                <Route path='/privacy-policy' element={<Privacy />} />

                {/* Precission Teaching */}
                <Route path='/precision-teaching' element={<PT />} />

                 {/* Blogs */}
                <Route path='/blogs' element={<BlogList />} />
                <Route path='/blogs/:blog' element={<BlogPage />} />

                 {/* Landing Pages */}
                <Route path='/learn' element={<LandingPage />} />
                <Route path='/learn/middleschool/:subjects' element={<SubjectPage />} />
                <Route path='/learn/igcse/:subjects' element={<SubjectPage />} />
                <Route path='/learn/a-levels/:subjects' element={<SubjectPage />} />
                
                <Route path='/learn-1' element={<LandingPage1 />} />
                <Route path='/learn-1/middleschool/:subjects' element={<SubjectPage />} />
                <Route path='/learn-1/igcse/:subjects' element={<SubjectPage />} />
                <Route path='/learn-1/a-levels/:subjects' element={<SubjectPage />} />
                
                <Route path='/learn-2' element={<LandingPage2 />} />
                <Route path='/learn-2/middleschool/:subjects' element={<SubjectPage />} />
                <Route path='/learn-2/igcse/:subjects' element={<SubjectPage />} />
                <Route path='/learn-2/a-levels/:subjects' element={<SubjectPage />} />

                <Route path='/learn-3' element={<LandingPage3 />} />
                <Route path='/learn-3/middleschool/:subjects' element={<SubjectPage />} />
                <Route path='/learn-3/igcse/:subjects' element={<SubjectPage />} />
                <Route path='/learn-3/a-levels/:subjects' element={<SubjectPage />} />

                <Route path='/learn-4' element={<LandingPage4 />} />
                <Route path='/learn-4/middleschool/:subjects' element={<SubjectPage />} />
                <Route path='/learn-4/igcse/:subjects' element={<SubjectPage />} />
                <Route path='/learn-4/a-levels/:subjects' element={<SubjectPage />} />

                <Route path='/learn-5' element={<LandingPage5 />} />
                <Route path='/learn-5/middleschool/:subjects' element={<SubjectPage />} />
                <Route path='/learn-5/igcse/:subjects' element={<SubjectPage />} />
                <Route path='/learn-5/a-levels/:subjects' element={<SubjectPage />} />

                <Route path='/learn-6' element={<LandingPage6 />} />
                <Route path='/learn-6/middleschool/:subjects' element={<SubjectPage />} />
                <Route path='/learn-6/igcse/:subjects' element={<SubjectPage />} />
                <Route path='/learn-6/a-levels/:subjects' element={<SubjectPage />} />

                <Route path='/learn-7' element={<LandingPage7 />} />
                <Route path='/learn-7/middleschool/:subjects' element={<SubjectPage />} />
                <Route path='/learn-7/igcse/:subjects' element={<SubjectPage />} />
                <Route path='/learn-7/a-levels/:subjects' element={<SubjectPage />} />

                <Route path='/learn-8' element={<LandingPage8 />} />
                <Route path='/learn-8/middleschool/:subjects' element={<SubjectPage />} />
                <Route path='/learn-8/igcse/:subjects' element={<SubjectPage />} />
                <Route path='/learn-8/a-levels/:subjects' element={<SubjectPage />} />

                <Route path='/learn-9' element={<LandingPage9 />} />
                <Route path='/learn-9/middleschool/:subjects' element={<SubjectPage />} />
                <Route path='/learn-9/igcse/:subjects' element={<SubjectPage />} />
                <Route path='/learn-9/a-levels/:subjects' element={<SubjectPage />} />

                <Route path='/learn-10' element={<LandingPage10 />} />
                <Route path='/learn-10/middleschool/:subjects' element={<SubjectPage />} />
                <Route path='/learn-10/igcse/:subjects' element={<SubjectPage />} />
                <Route path='/learn-10/a-levels/:subjects' element={<SubjectPage />} />

                <Route path='/remotework' element={<RemoteLandingPage />} />
                <Route path='/remotework/middleschool/:subjects' element={<SubjectPage />} />
                <Route path='/remotework/igcse/:subjects' element={<SubjectPage />} />
                <Route path='/remotework/a-levels/:subjects' element={<SubjectPage />} />
                
            </Routes>
          </Suspense>
          <FAQ />
        </Router>
        <FooterComponent />
      </div>
    </HelmetProvider>
  );
}

export default App;

/* 
EAAWF31VGSssBO8BOZAPcWoWpdzZBdOZBZBJ1NWNYvKTDAVQe6mjDlC1gqyuWWXCCgnGjJBOR15hR1vPvQbqtVSEGGjVPJx5KvPUGzNL8BZCGt78BhTqaxvpKwYenXV18bzIFXPQNWHJCTvNjoQO8jQZCZBIiflFEkvqTJXWb2xV3HgqFQKLhtRRSxXtGbBYOOxdBAZDZD
*/